import { PanelsApiFactory } from '@wix/blocks-widget-services/panels';
import categoriesLayout from '../../../components/Post Categories/blocksPanels/Categories Layout/.component.json';
import editCategories from '../../../components/Post Categories/blocksPanels/Edit Categories/.component.json';
import countersLayout from '../../../components/Post Counters New/blocksPanels/Counters Layout 1/.component.json';
import dividerLayout from '../../../components/Post Divider/blocksPanels/Divider Layout/.component.json';
import footerLayout from '../../../components/Post Footer/blocksPanels/Header Layout 1/.component.json';
import { openFooterElementsPanel } from '../../../components/Post Footer/utils/elements-panel';
import headerLayout from '../../../components/Post Header/blocksPanels/Header Layout/.component.json';
import { openHeaderElementsPanel } from '../../../components/Post Header/utils/elements-panel';
import metadataLayout from '../../../components/Post Metadata/blocksPanels/Metadata Layout/.component.json';
import postPageLayout from '../../../components/Post Page/blocksPanels/Post Page Layout/.component.json';
import { openPostPageElementsPanel } from '../../../components/Post Page/post-page-elements-panel';
import ratingsLayout from '../../../components/Post Ratings/blocksPanels/Ratings Layout/.component.json';
import shareButtonsLayout from '../../../components/Post Share Buttons/blocksPanels/Share Button Layout/.component.json';
import tagsLayout from '../../../components/Post Tags/blocksPanels/Tags Layout/.component.json';
import titleLayout from '../../../components/Post Title/blocksPanels/Title layout/.component.json';
import { openChangePresets } from '../../../editor/blocks-post-page-open-change-presets';
import { EditorAppContext } from '../../../types/editor-app-context.type';
import { openManagePostsDashboard } from './handlers';

export const eventListenersSplitPostPage = async (
  context: EditorAppContext,
) => {
  const panelsApi = await new PanelsApiFactory()?.createPanelsApi?.({
    editorSDK: context.sdk,
    editorType: context.editorType,
    essentials: context.essentials,
  });
  const openBlocksPanelPrefix = 'open-blocks-panel: ';

  await context.sdk.addEventListener('componentGfppClicked', async (event) => {
    const { id, componentRef } = event.detail;

    if (id === `${openBlocksPanelPrefix}<Title layout>`) {
      panelsApi.openBlocksPanel(titleLayout.id, componentRef);
    }
  });

  await context.sdk.addEventListener('widgetGfppClicked', async (event) => {
    const { id, componentRef } = event.detail;

    // prettier-ignore
    const eventHandlerMap = {
      openPostPageElementsPanel: () => openPostPageElementsPanel(context, componentRef),
      openShowHidePanel: () => openHeaderElementsPanel(context, componentRef),
      openShowHideFooterPanel: () => openFooterElementsPanel(context, componentRef),
      openManagePosts: () => openManagePostsDashboard(context),
      openChangePresets: () => openChangePresets({context, componentRef}),
      [`${openBlocksPanelPrefix}<${headerLayout.id}>`]:       () => panelsApi.openBlocksPanel(headerLayout.id, componentRef),
      [`${openBlocksPanelPrefix}<${headerLayout.id}>`]:       () => panelsApi.openBlocksPanel(headerLayout.id, componentRef),
      [`${openBlocksPanelPrefix}<${footerLayout.id}>`]:       () => panelsApi.openBlocksPanel(footerLayout.id, componentRef),
      [`${openBlocksPanelPrefix}<${tagsLayout.id}>`]:         () => panelsApi.openBlocksPanel(tagsLayout.id, componentRef),
      [`${openBlocksPanelPrefix}<${countersLayout.id}>`]:     () => panelsApi.openBlocksPanel(countersLayout.id, componentRef),
      [`${openBlocksPanelPrefix}<${categoriesLayout.id}>`]:   () => panelsApi.openBlocksPanel(categoriesLayout.id, componentRef),
      [`${openBlocksPanelPrefix}<${editCategories.id}>`]:     () => panelsApi.openBlocksPanel(editCategories.id, componentRef),
      [`${openBlocksPanelPrefix}<${ratingsLayout.id}>`]:      () => panelsApi.openBlocksPanel(ratingsLayout.id, componentRef),
      [`${openBlocksPanelPrefix}<${dividerLayout.id}>`]:      () => panelsApi.openBlocksPanel(dividerLayout.id, componentRef),
      [`${openBlocksPanelPrefix}<${metadataLayout.id}>`]:     () => panelsApi.openBlocksPanel(metadataLayout.id, componentRef),
      [`${openBlocksPanelPrefix}<${shareButtonsLayout.id}>`]: () => panelsApi.openBlocksPanel(shareButtonsLayout.id, componentRef),
      [`${openBlocksPanelPrefix}<${postPageLayout.id}>`]: () => panelsApi.openBlocksPanel(postPageLayout.id, componentRef),
    };

    await eventHandlerMap[id]?.();
  });
};
