import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

export const getWidgetManifest: GetWidgetManifestFn = (_, editorSDK) => {
  editorSDK.addEventListener('globalDesignPresetChanged', async (event) => {
    const { componentRef } = event.detail;
    const [parentRefComponent] = await editorSDK.components.getAncestors('', {
      componentRef,
    });

    await editorSDK.components.refComponents.removeAllOverrides('', {
      componentRef: parentRefComponent,
    });
  });
};
